var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-info-submit-box"},[_vm._m(0),_c('a-form',{staticClass:"form-box",attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'messageName',
              {rules: [{ required: true, message: '请填写此项' }]}]),expression:"[\n              'messageName',\n              {rules: [{ required: true, message: '请填写此项' }]}]"}],attrs:{"autocomplete":"off","placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'messageIphone',
              {rules: [{ required: true, message: '请填写此项' },{validator: _vm.phoneVerify}]}]),expression:"[\n              'messageIphone',\n              {rules: [{ required: true, message: '请填写此项' },{validator: phoneVerify}]}]"}],attrs:{"autocomplete":"off","placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'messageEmail',
              {rules: [{ type: 'email',message: '格式不正确' },{ required: true, message: '请填写此项' }]}]),expression:"[\n              'messageEmail',\n              {rules: [{ type: 'email',message: '格式不正确' },{ required: true, message: '请填写此项' }]}]"}],attrs:{"autocomplete":"off","placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"公司"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'messageCompany',
              {rules: [{ required: true, message: '请填写此项' }]}]),expression:"[\n              'messageCompany',\n              {rules: [{ required: true, message: '请填写此项' }]}]"}],attrs:{"autocomplete":"off","placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'messageRemark']),expression:"[ 'messageRemark']"}],attrs:{"placeholder":"请输入","autosize":{ minRows: 2, maxRows: 6 }}}),_c('span',{staticClass:"btn",on:{"click":_vm.handleSubmit}},[_vm._v("确认")])],1)],1),_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("提交完成后，我们会尽快联系您，感谢支持！"),_c('router-link',{attrs:{"to":"/MIndex"}},[_vm._v("返回首页")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg"},[_c('p',[_vm._v("立即体验"),_c('br'),_c('span',[_vm._v("成为郑大智能体验官")])])])}]

export { render, staticRenderFns }