<template>
  <div class="m-info-submit-box">
        <div class="bg">
          <p>立即体验<br/><span>成为郑大智能体验官</span></p>
        </div>
        <a-form class="form-box" :form="form">
            <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            label="姓名" >
            <a-input
                autocomplete="off"
                v-decorator="[
                'messageName',
                {rules: [{ required: true, message: '请填写此项' }]}]"
                placeholder="请输入" />
            </a-form-item>
            <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            label="电话" >
            <a-input
                autocomplete="off"
                v-decorator="[
                'messageIphone',
                {rules: [{ required: true, message: '请填写此项' },{validator: phoneVerify}]}]"
                placeholder="请输入" />
            </a-form-item>
             <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            label="邮箱" >
            <a-input
                autocomplete="off"
                v-decorator="[
                'messageEmail',
                {rules: [{ type: 'email',message: '格式不正确' },{ required: true, message: '请填写此项' }]}]"
                placeholder="请输入" />
            </a-form-item>
             <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            label="公司" >
            <a-input
                autocomplete="off"
                v-decorator="[
                'messageCompany',
                {rules: [{ required: true, message: '请填写此项' }]}]"
                placeholder="请输入" />
            </a-form-item>
             <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                label="备注" >
                <a-textarea  v-decorator="[ 'messageRemark']"
                placeholder="请输入"  :autosize="{ minRows: 2, maxRows: 6 }" />
                <span class="btn" @click="handleSubmit">确认</span>
            </a-form-item>
      </a-form>
      <p style="text-align: center;">提交完成后，我们会尽快联系您，感谢支持！<router-link to='/MIndex'>返回首页</router-link></p>
  </div>
</template>
<script>
import { addMessage } from '@/api/data'
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 17 }
}
  export default {
    props:{
    },
    data() {
      return {
        formItemLayout,
        form: this.$form.createForm(this),
      }
    },
    methods: {
      hideModal() {
       this.form.resetFields()
      },
      phoneVerify (rule, value, callback) {
        if (value !== '' && value !== undefined) {
            if (!(/^1[3-9]\d{9}$/.test(value))) {
            callback(new Error('手机号格式不正确'))
            } else {
            callback()
            }
        }
        callback()
     },
      handleSubmit () {
        let that=this
        this.form.validateFields((errors, values) => {
          if (!errors) {
             addMessage(values).then(res => {
                if (res.state) {
                    this.$message.success('提交成功')
                    that.hideModal()
                }else{
                    this.$message.error('提交失败')
                    that.hideModal()
                }
            }).catch(res => {
              console.log(res)
            })
          }
        })
      } 
    },
    mounted() {
    },
  };
</script>
<style lang="less" scoped>
.m-info-submit-box{
  background: #fff;
  border-bottom: 1px solid #ccc;
  padding-bottom: .4rem /* 30/75 */;
  min-height: 90vh;
  .bg{
    background: url(../../../assets/indexImg/titleBg.jpg) no-repeat;
    background-size: 100% 100%;
    padding: 24px;
    text-align: center;
    height: 2.666667rem /* 200/75 */;
    p{
      font-size: .426667rem /* 32/75 */;
      color:#fff;
      padding-top: .266667rem /* 20/75 */;
      span{
        font-size: .373333rem /* 28/75 */
      }
    }
  }
 
  p{
    margin-bottom: 0;
    font-size: .32rem /* 24/75 */;
  }
  .btn{
    display: block;
    height:.88rem /* 66/75 */;
    line-height:.88rem /* 66/75 */;
    background: #1f74f1;
    margin:.4rem /* 30/75 */ auto .666667rem /* 50/75 */;
    color:#fff;
    border-radius: 4px;
  }
}
  
</style>
<style lang="less">
.m-info-submit-box{
 .form-box{
    padding:.533333rem /* 40/75 */ .533333rem /* 40/75 */ 0;
    .ant-form-item{
      .ant-col-6{
        padding-top:.2rem /* 15/75 */;
        font-size: .373333rem /* 28/75 */;
        padding-left: .8rem /* 60/75 */;
      }
      &:last-child{
        .ant-col-6{
          text-indent: .266667rem /* 20/75 */;
        }
      }
    }
  }
}
</style>